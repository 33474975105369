// -------------- THEME SWITCHER -------------- //
@mixin dark-appearance {
  filter: invert(1);
  img {
    filter: invert(1);

    &.ioda { filter: invert(0); }
  }
}

body[a="dark"] { @include dark-appearance; }


@media (prefers-color-scheme: dark) {
  body[a="auto"] { @include dark-appearance; }
}
// -------------------------------------------- //

// bg color is also needed in html in order to
// block body's background propagation
// see: https://stackoverflow.com/a/61265706
html, body { background: white; }

html { height: 100%; }

body {
  color: black;
  font-family: monospace;
  font-size: 16px;
  line-height: 1.4;
  margin: 0;
  min-height: 100%;
  overflow-wrap: break-word;
}

.post-meta { text-align: right; }

h2, h3 { margin: 3rem 0 1rem 0; }

h4, h5, h6 { margin: 2rem 0 0 0; }

hr { margin: 2rem 0; }

p { margin: 1.5rem 0 1rem 0; }

ul { margin: 0.5rem 0 1rem 0; }

li { margin: 0.3rem 0; }

*:target { background: yellow; }

.w {
  max-width: 640px;
  margin: 0 auto;
  padding: 4rem 2rem;
}

hr {
  text-align: center;
  border: 0;
  margin-top: 6rem;

  &:before { content: '/////' }
  &:after { content: attr(data-content) '/////' }
}

table { width: 100%; }

table, th, td {
  border: thin solid black;
  border-collapse: collapse;
  padding: 0.4rem;
}

code {
  color: white;
  background: black;
}

div.highlighter-rouge code {
  display: block;
  overflow-x: auto;
  white-space: pre-wrap;
  padding: 1rem;
}

blockquote {
  font-style: italic;
  border: thin solid black;
  padding: 1rem;

  p { margin: 0; }
}

img {
  max-width: 100%;
  display: block;
  margin: 0 auto;
}

form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

label {
  margin-bottom: 0.1rem;
}

input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
}

input[type="submit"],
input[type="reset"] {
  width: 100%;
  padding: 0.75rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  box-sizing: border-box;
}

input[type="submit"].special {
  background-color: #000000;
  color: white;
}

input[type="reset"] {
  background-color: #f8f9fa;
  color: #212529;
}
